import React from "react";
import Helmet from "react-helmet";
import { SEO } from "../components/seo";
import Layout from "../components/layout";
import { graphql } from "gatsby";

import { Card } from "../components/projects/Card";

const projects = [
  {
    name: "FirebaseElevate",
    description: "Enhancing your Firebase experience - designed to simplify navigation within Firestore. ",
    link: {
      href:
        "https://chrome.google.com/webstore/detail/firebaseelevate/gdkmeifhinconkjfelkgmamndiimafcb",
      label: "FirebaseElevate@Chrome",
    },
    logo:
      "https://lh3.googleusercontent.com/uwRkaz1uYocpWqYpsqmDIlR6oJF_XlwHUEyaz74FwvNPx_IqREk-d5oxUrGAK1slUQF9YmuATv8yL0t-ufC08qLXWg=w128-h128-e365-rj-sc0x00ffffff",
  },
];

function LinkIcon(props) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        d="M15.712 11.823a.75.75 0 1 0 1.06 1.06l-1.06-1.06Zm-4.95 1.768a.75.75 0 0 0 1.06-1.06l-1.06 1.06Zm-2.475-1.414a.75.75 0 1 0-1.06-1.06l1.06 1.06Zm4.95-1.768a.75.75 0 1 0-1.06 1.06l1.06-1.06Zm3.359.53-.884.884 1.06 1.06.885-.883-1.061-1.06Zm-4.95-2.12 1.414-1.415L12 6.344l-1.415 1.413 1.061 1.061Zm0 3.535a2.5 2.5 0 0 1 0-3.536l-1.06-1.06a4 4 0 0 0 0 5.656l1.06-1.06Zm4.95-4.95a2.5 2.5 0 0 1 0 3.535L17.656 12a4 4 0 0 0 0-5.657l-1.06 1.06Zm1.06-1.06a4 4 0 0 0-5.656 0l1.06 1.06a2.5 2.5 0 0 1 3.536 0l1.06-1.06Zm-7.07 7.07.176.177 1.06-1.06-.176-.177-1.06 1.06Zm-3.183-.353.884-.884-1.06-1.06-.884.883 1.06 1.06Zm4.95 2.121-1.414 1.414 1.06 1.06 1.415-1.413-1.06-1.061Zm0-3.536a2.5 2.5 0 0 1 0 3.536l1.06 1.06a4 4 0 0 0 0-5.656l-1.06 1.06Zm-4.95 4.95a2.5 2.5 0 0 1 0-3.535L6.344 12a4 4 0 0 0 0 5.656l1.06-1.06Zm-1.06 1.06a4 4 0 0 0 5.657 0l-1.061-1.06a2.5 2.5 0 0 1-3.535 0l-1.061 1.06Zm7.07-7.07-.176-.177-1.06 1.06.176.178 1.06-1.061Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default function Projects() {
  return (
    <>
      <Layout>
        <SEO />
        <Helmet>
          <title>My Projects — Edwards Moses</title>
        </Helmet>

        <div className="lg:order-first lg:row-span-2">
          <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
            my Projects
          </h1>
          <div className="mt-6 space-y-7 text-base text-zinc-600 dark:text-zinc-400 mb-16">
            <p>
              I’ve worked on tons of projects over the years but these are the ones ('one' for now) that I’ve founded
              and most proud of. <br />
              Many of them are open-source, so if you see something that sparks your interest, check out the code and
              contribute if you have ideas for how it can be improved - I'll appreciate the collab.
            </p>
          </div>
        </div>

        <div className="relative px-4 sm:px-8 lg:px-12">
          <ul role="list" className="grid grid-cols-1 gap-x-12 gap-y-16 sm:grid-cols-2 lg:grid-cols-3">
            {projects.map((project) => (
              <div className="flex flex-col">
                <Card as="li" key={project.name}>
                  <div className="relative z-10 flex h-12 w-12 items-center justify-center rounded-full bg-white shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 dark:border dark:border-zinc-700/50 dark:bg-zinc-800 dark:ring-0">
                    <img src={project.logo} alt="" className="h-8 w-8" />
                  </div>
                  <h2 className="mt-6 text-base font-semibold text-zinc-800 dark:text-zinc-100">
                    <Card.Link href={project.link.href}>{project.name}</Card.Link>
                  </h2>
                  <Card.Description>{project.description}</Card.Description>
                  <p className="relative z-10 mt-6 flex text-sm font-medium text-zinc-400 transition group-hover:text-teal-500 dark:text-zinc-200">
                    <LinkIcon className="h-6 w-6 flex-none" />
                    <span
                      className="ml-2">{project.link.label}</span>
                  </p>
                </Card>
                <div>
                  <a href="https://www.producthunt.com/posts/firebaseelevate?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-firebaseelevate" target="_blank">
                    <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=381786&theme=light"
                      alt="FirebaseElevate - Elevate&#0032;your&#0032;Firebase&#0032;experience&#0033; | Product Hunt"
                      width="250" height="54" style={{
                        width: 250,
                        height: 54,
                      }} />
                  </a>
                </div>

              </div>

            ))}
          </ul>
        </div>
      </Layout>
    </>
  );
}

export const pageQuery = graphql`
  query ProductPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
